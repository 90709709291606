/* Phones and Tablet*/
@media (max-width: 600px) {
  .anuncio {
    height: 100px;
    text-align: center;
    padding: 0 20px;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .anuncio {
    height: 100px;
    text-align: center;
    padding: 0 20px;
  }
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
