.suggestion {
  text-align: center;
  padding: 20px;
  margin-top: 40px;
  background-color: #2d005b;
}

.suggestion__container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 0;
}

.suggestion__container__title h2 {
  font-weight: 400;
  padding-bottom: 40px;
  align-items: center;
  margin: 0;
}

.suggestion__container__datos {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.suggestion__container__datospersonales {
  display: flex;
}

.suggestion__container__datospersonales li {
  margin: 0 5px;
}

.suggestion__container__datospersonales li input {
  width: 100%;
}

.suggestion__container__datossugerencias li input {
  width: 100%;
}

.suggestion__container__datos ul li input {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 10px;
  border: none;
  background-color: white;
  opacity: 1;
  color: #111;
  font-size: 1rem;
  text-align: center;
}

.suggestion__container__datos ul li input::placeholder {
  padding: 0 20px;
  text-align: center;
}

.suggestion__container__enviar {
  padding: 15px;
  border: none;
  background-color: #111;
  cursor: pointer;
  font-weight: 500;
  border-radius: 10px;
  width: 100%;
  margin: 0;
  font-size: 1.4rem;
  color: lightblue;
}

.suggestion__container__enviar:hover {
  background-color: #111;
  color: white;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
