.copyright {
  margin: 0;
  color: darkgrey;
  opacity: 0.7;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  font-size: 0.9rem;
}

.copyright > p {
  padding: 5px;
  text-align: center;
}
