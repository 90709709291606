.checkout {
  display: flex;
  padding: 20px;
  background-color: white;
  height: max-content;
}

.checkout__left__untitle {
  margin-right: 10px;
  padding: 10px;
  border-bottom: 1px solid lightgray;
  max-width: 450px;
}

.checkout__left__unsubtitle {
  padding: 20px 0 0 10px;
}

.checkout__left__title {
  margin-right: 10px;
  padding: 10px;
  border-bottom: 1px solid lightgray;
  max-width: 450px;
}

.checkout__left__subtitle {
  margin-right: 10px;
  padding: 10px;
  max-width: 450px;
}

.checkout__right {
  padding: 20px;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .checkout {
    display: grid;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .checkout {
    display: grid;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
