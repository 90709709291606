.nav__checkout__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav__checkout__msg {
  font-size: 14px;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .nav__checkout__msg {
    display: none;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
