.continueshopping {
    display: flex;
    align-Items: center; 
    padding: 30px 0 0 60px; 
    opacity: 0.7;
}

.continueshopping p {
    padding-left: 5px;
    font-weight: 500;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
.continueshopping {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 30px 0 20px 0;
    margin: 0 auto;
}

.product__wrapper__container {
    display: flex;
    flex-direction: column;
}
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
