.anuncio {
  text-align: center;
  padding: 0 20px;
}

.anuncio__container {
  display: flex;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .anuncio {
    text-align: center;
    padding: 0 20px;
  }

  .anuncio__container {
    display: flex;
    flex-direction: column;
  }
  
  .anuncio__container__left span {
    display: none;
  }

  .anuncio__container__left img {
    display: none;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
