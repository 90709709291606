.thanks__banner {
  display: flex;
  width: 100%;
  height: 90vh;
  margin-bottom: 40px;
}

.thanks__content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.thanks__content__back {
  display: flex;
  flex: 1;
  text-align: left;
  align-items: center;
  padding-left: 20px;
}

.thanks__content__back:hover {
  opacity: 1;
  color: darkblue;
}

.thanks__content__back > a {
  text-decoration: none;
  color: lightgray;
  font-size: 1rem;
  opacity: 0.8;
}

.thanks__content__delivery {
  flex: 1;
  text-align: center;
  max-width: 350px;
}

.thanks__content__delivery > h1 {
  padding: 20px 0;
  font-size: 2.4rem;
}

.thanks__content__delivery > p {
  font-size: 1.5rem;
}

.thanks__content__go {
  display: flex;
  flex: 1;
  text-align: right;
  align-items: center;
  justify-content: right;
  padding-right: 20px;
}

.thanks__content__go:hover {
  opacity: 1;
  color: darkblue;
}

.thanks__content__go > a {
  text-decoration: none;
  color: lightgray;
  font-size: 1rem;
  opacity: 0.8;
}
