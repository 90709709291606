.subtotal {
  padding: 0 40px 20px 40px;
  position: sticky;
  align-self: flex-start;
  top: 80px;
}

.subtotal__title {
  padding-bottom: 20px;
}

.subtotal__monto {
  padding: 10px 0;
  /* background-color: rgba(10, 10, 10, 0.1); */
}

.subtotal__monto > p {
  font-size: 1.2rem;
  font-weight: 400;
}

.subtotal__monto p > span {
  font-weight: 600;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.subtotal__datos {
  padding: 40px 0;
}

.subtotal__datos > h2 {
  padding: 20px 0 40px 0;
}

.subtotal__datos ul li input {
  width: 90%;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 10px;
  border: none;
  background-color: lightgray;
  opacity: 0.7;
  color: black;
  font-size: 1rem;
  padding-left: 20px;
}

.subtotal__datos ul li input::placeholder {
  padding-left: 20px;
}

.subtotal__metodosdepago {
  padding: 40px 0;
}

.subtotal__metodosdepago > h2 {
  padding: 20px 0 40px 0;
}

.subtotal__pagar {
  padding: 25px 15px;
  border: 2px solid #390072;
  background-color: white;
  cursor: pointer;
  font-weight: 500;
  border-radius: 10px;
  width: 95%;
  margin: 0 0 40px 0;
  font-size: 1.5rem;
  color: lightblue;
}

.subtotal__pagar:hover {
  background-color: #390072;
  color: white;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .subtotal {
    padding: 0;
    position: relative;
    flex-direction: column;
    align-self: flex-start;
    top: 0px;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
