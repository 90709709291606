.productCart {
  display: flex;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.productCart__info {
  padding-bottom: 10px;
}

.productCart__container {
  flex-direction: column;
  padding: 30px;
}

.productCart__image {
  object-fit: contain;
  width: 160px;
  height: 160px;
}

.productCart__theme {
  font-weight: bold;
}

.productCart__removebutton {
  padding: 15px;
  border: none;
  background-color: lightgray;
  cursor: pointer;
  font-weight: 400;
  border-radius: 10px;
}

.productCart__removebutton:hover {
  background-color: red;
  border: none;
  color: white;
}
