.paypalbox {
  display: grid;
  place-items: left;
}

.paypalbox__contents__donation {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.paypalbox__contents__donation > p {
  color: #111;
  padding-right: 10px;
}

.paypalbox__contents__donation input {
  height: 12px;
  border: 1px solid;
  border-radius: 5px;
  width: auto;
  color: #111;
  font-size: 1.2rem;
  padding: 12px;
}
